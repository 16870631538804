// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import Home from "./pages/Home";
import Dialog from "./pages/Dialog";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./auth/authContext";
import Content from "./pages/Content";
import { ThemeProvider, useTheme } from "./theme/theme";
import Investigate from "./pages/Investigate";
import Context from "./pages/Context"

const App = () => {
    return (
        <AuthProvider>
            <ThemeProvider>
                <AppContent />
            </ThemeProvider>
        </AuthProvider>
    );
};

const AppContent = () => {
    const { theme } = useTheme();

    return (
        <div className={`app ${theme}`}>
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/dialog"
                    element={
                        <ProtectedRoute>
                            <Dialog />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dialog/:threadId"
                    element={
                        <ProtectedRoute>
                            <Dialog />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/content"
                    element={
                        <ProtectedRoute>
                            <Content />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/content/:contentId"
                    element={
                        <ProtectedRoute>
                            <Content />
                        </ProtectedRoute>
                    }
                />
                 <Route
                    path="/investigate"
                    element={
                        <ProtectedRoute>
                            <Investigate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/context/"
                    element={
                        <ProtectedRoute>
                            <Context />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/context/:contextId"
                    element={
                        <ProtectedRoute>
                            <Context />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    }
                />
                <Route path="/login" element={<Login />} />
            </Routes>
        </div>
    );
};

export default App;
