import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { FaCopy, FaEllipsisV, FaTrashAlt } from "react-icons/fa";
import DropdownItemConfig from "./DropdownItemConfig";
import { OptionButton } from "../buttons/Button";

export const DropdownActions = ({
  handlePrimaryAction,
  handleCopyUrl,
  toggleDeleteModal,
  showCopyAlert,
  primaryActionIcon,
  primaryActionLabel,
  showCopyButton = true,
  disabled,
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="text-end m-3">
      <div className="d-none d-md-flex justify-content-end">
        <OptionButton
          color="primary"
          onClick={handlePrimaryAction}
          className="me-2"
          icon={primaryActionIcon}
          label={primaryActionLabel}
          textColor="blue"
          borderColor="blue"
          disabled={disabled}

        />
        {showCopyButton && (
          <OptionButton
            color="secondary"
            onClick={handleCopyUrl}
            className="me-2"
            icon={FaCopy}
            label={t("copy_url")}
            textColor="black"
            borderColor="black"
            disabled={disabled}

          />
        )}

        <OptionButton
          color="danger"
          onClick={toggleDeleteModal}
          icon={FaTrashAlt}
          label={t("delete")}
          textColor="red"
          borderColor="red"
          disabled={disabled}
        />
      </div>
      <div className="d-md-none">
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle
            caret
            className="text-primary bg-white border border-primary"
          >
            <FaEllipsisV />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItemConfig
              onClick={handlePrimaryAction}
              className="text-primary"
              icon={primaryActionIcon}
              label={primaryActionLabel}
            />
            {showCopyButton && (
              <DropdownItemConfig
                onClick={handleCopyUrl}
                icon={FaCopy}
                label={t("copy_url")}
              />
            )}

            <DropdownItemConfig
              onClick={toggleDeleteModal}
              className="text-danger"
              icon={FaTrashAlt}
              label={t("delete")}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
      {showCopyAlert && (
        <div className="text-center">
          <Alert color="success">{t("url_copied")}</Alert>
        </div>
      )}
    </div>
  );
};
