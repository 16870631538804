import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';  // Legg til denne importen
import { useAuthStatus, useAuthInitialization } from '../auth/authContext';
import Loader from "./loader/Loader";

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useAuthStatus();
    const isInitialized = useAuthInitialization();
    const location = useLocation();

    console.log("Is Authenticated:", isAuthenticated);
    console.log("Is Initialized:", isInitialized);

    if (!isInitialized) {
        return <Loader />
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute;
