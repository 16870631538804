import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from './authConfig';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Endret til named import

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const msalInstance = useMemo(() => new PublicClientApplication(msalConfig), []);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const navigate = useNavigate();

    // Funksjon for å sjekke token gyldighet
    const isTokenValid = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp > currentTime; // Sjekk at token ikke er utløpt
        } catch (error) {
            console.error("Failed to decode token:", error);
            return false; // Hvis token ikke kan dekodes, anse det som ugyldig
        }
    };

    // Bruk useCallback for å forhindre at logout referansen endres mellom renders
    const logout = useCallback(async () => {
        try {
            await msalInstance.logoutPopup();
            setIsAuthenticated(false);
            navigate('/login');
        } catch (error) {
            console.error("Logout failed:", error);
        }
    }, [msalInstance, navigate]);

    // Effekt for å validere brukers autentisering ved lasting av siden
    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await msalInstance.initialize();
                const response = await msalInstance.handleRedirectPromise();

                if (response !== null) {
                    msalInstance.setActiveAccount(response.account);
                    setIsAuthenticated(true);
                } else {
                    const account = msalInstance.getAllAccounts()[0];
                    if (account) {
                        msalInstance.setActiveAccount(account);
                        const tokenResponse = await msalInstance.acquireTokenSilent({
                            ...loginRequest,
                            account,
                        });

                        if (isTokenValid(tokenResponse.idToken)) {
                            setIsAuthenticated(true); // Logg inn hvis token er gyldig
                        } else {
                            setIsAuthenticated(false);
                            await logout(); // Logg ut brukeren hvis token er utløpt
                        }
                    } else {
                        setIsAuthenticated(false); // Ingen konto, ikke autentisert
                    }
                }
            } catch (error) {
                console.error("Error during MSAL initialization:", error);
                setIsAuthenticated(false); // Feil under autentisering, marker som ikke innlogget
            } finally {
                setIsInitialized(true); // Marker at MSAL er initialisert
            }
        };

        initializeMsal(); // Kjør initialisering av MSAL
    }, [msalInstance, logout]); // Legg til logout som avhengighet

    // Login funksjon
    const login = async () => {
        try {
            await msalInstance.loginPopup(loginRequest);
            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                msalInstance.setActiveAccount(account);
                setIsAuthenticated(true);
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    // Hente ID-token med validering av gyldighet
    const getIdToken = async () => {
        if (!isInitialized) {
            console.error("MSAL is not initialized");
            return null;
        }

        const account = msalInstance.getActiveAccount();
        if (!account) {
            console.error("No active account found");
            return null;
        }

        try {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account,
            });
            if (!isTokenValid(response.idToken)) {
                throw new Error("Token expired");
            }
            console.log(response);
            return response.idToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error.message === "Token expired") {
                try {
                    const response = await msalInstance.acquireTokenPopup({
                        ...loginRequest,
                        account,
                    });
                    if (!isTokenValid(response.idToken)) {
                        throw new Error("Token expired");
                    }
                    return response.idToken;
                } catch (popupError) {
                    console.error("Failed to acquire token with popup:", popupError);
                    await logout();
                    return null;
                }
            } else {
                console.error("Failed to acquire token silently:", error);
                await logout();
                return null;
            }
        }
    };

    // Hente adgangstoken (access token) med validering
    const getAccessToken = async () => {
        if (!isInitialized) {
            console.error("MSAL is not initialized");
            return null;
        }

        const account = msalInstance.getActiveAccount();
        if (!account) {
            console.error("No active account found");
            return null;
        }

        try {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account,
            });
            if (!isTokenValid(response.accessToken)) {
                throw new Error("Token expired");
            }
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error.message === "Token expired") {
                try {
                    const response = await msalInstance.acquireTokenPopup({
                        ...loginRequest,
                        account,
                    });
                    if (!isTokenValid(response.accessToken)) {
                        throw new Error("Token expired");
                    }
                    return response.accessToken;
                } catch (popupError) {
                    console.error("Failed to acquire token with popup:", popupError);
                    await logout();
                    return null;
                }
            } else {
                console.error("Failed to acquire token silently:", error);
                await logout();
                return null;
            }
        }
    };

    return (
        <AuthContext.Provider value={{ instance: msalInstance, isAuthenticated, isInitialized, login, logout, getIdToken, getAccessToken }}>
            <MsalProvider instance={msalInstance}>
                {children}
            </MsalProvider>
        </AuthContext.Provider>
    );
};

// Hook for å bruke autentiseringskonteksten
export const useAuth = () => useContext(AuthContext);
export const useAuthStatus = () => {
    const context = useAuth();
    return context.isAuthenticated;
};
export const useAuthInitialization = () => {
    const context = useAuth();
    return context.isInitialized;
};
