import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardText,
} from "reactstrap";
import {
  FaRegFileAlt,
  FaAlignLeft,
  FaTag,
  FaLink,
  FaEdit,
  FaPlus,
} from "react-icons/fa";
import "./ContentInfo.css";
import { useTheme } from "../../theme/theme";
import { SecondaryButton } from "../buttons/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "../feedbackModal/FeedbackModal";
import { DeleteModal } from "../deleteModal/DeleteModal";
import { DropdownActions } from "../dropdownAction/DropdownAction";
import useContent from "../../hooks/useContent";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

const ContentInfo = ({ content, onEdit, onSuccess }) => {
  const { theme } = useTheme();
  const [modal, setModal] = useState(false);
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackColor, setFeedbackColor] = useState("success");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteContentItem } = useContent();

  useEffect(() => {
    if (feedbackModalOpen) {
      const timer = setTimeout(() => setFeedbackModalOpen(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [feedbackModalOpen]);

  const toggleDeleteModal = () => setModal(!modal);

  const handleDelete = async () => {
    try {
      await deleteContentItem(content.id);
      toggleDeleteModal();
      setFeedbackMessage(t("delete_success"));
      setFeedbackColor("success");
      setFeedbackModalOpen(true);
      setTimeout(() => onSuccess(), 3000);
    } catch (error) {
      console.error("Error deleting content:", error);
      setFeedbackMessage(t("delete_content_error"));
      setFeedbackColor("danger");
      setFeedbackModalOpen(true);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          setShowCopyAlert(true);
          setTimeout(() => setShowCopyAlert(false), 2000);
        })
        .catch((err) => {
          console.error("Error copying URL:", err);
          setFeedbackMessage(t("copy_failed"));
          setFeedbackColor("danger");
          setFeedbackModalOpen(true);
        });
  };

  const handleCreateThread = () => {
    if (content) {
      const initialContentData = {
        contentIds: [content.id],
        contentTitles: [content.title],
      };
      console.log("Initial Content Data:", initialContentData);
      navigate("/dialog", { state: initialContentData });
    } else {
      console.log("No content available");
    }
  };

  return (
      <Container className="content-info">
        <Card
            style={{
              backgroundColor: theme === "light" ? "#ffffff" : "#000000",
              color: theme === "light" ? "#000000" : "#ffffff",
            }}
        >
          <DropdownActions
              handlePrimaryAction={handleCreateThread}
              handleCopyUrl={handleCopyUrl}
              toggleDeleteModal={toggleDeleteModal}
              showCopyAlert={showCopyAlert}
              primaryActionIcon={FaPlus}
              primaryActionLabel={t("create_thread")}
          />
          <CardBody>
            <CardTitle
                tag="h1"
                className="text-center mb-5"
                style={{
                  color:
                      theme === "light" ? "var(--primary)" : "var(--primary-dark)",
                }}
            >
              {content.title}
            </CardTitle>
            <ContentInfoItem
                content={content.description}
                label="description"
                icon={FaRegFileAlt}
            />
            <ContentInfoItem
                content={content.text}
                label="text"
                icon={FaAlignLeft}
            />
            <Row>
              <Col xl="3">
                <ContentInfoItem
                    content={content.type}
                    label="type"
                    icon={FaTag}
                />
              </Col>
              <Col xl="9">
                <ContentInfoItem url={content.url} label="url" icon={FaLink} />
              </Col>
            </Row>
            <div className="text-center">
              <SecondaryButton
                  label={t("edit_content")}
                  icon={FaEdit}
                  onClick={onEdit}
              />
            </div>
          </CardBody>
        </Card>
        <DeleteModal
            isOpen={modal}
            toggle={toggleDeleteModal}
            handleDelete={handleDelete}
        />
        <FeedbackModal
            isOpen={feedbackModalOpen}
            message={feedbackMessage}
            color={feedbackColor}
        />
      </Container>
  );
};

const ContentInfoItem = ({ content, label, icon: Icon, url }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  if (!Icon) return null;

  return (
      <div className="d-flex align-items-center p-3">
        <div className="me-5 d-none d-md-block">
          <Icon className="info-icon" />
        </div>
        <div>
          <h5
              style={{
                color: theme === "light" ? "var(--primary)" : "var(--primary-dark)",
              }}
          >
            {t(label)}:
          </h5>
          <CardText>
            <div className="markdown-content scrollable-content">
              <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
                {content}
              </ReactMarkdown>
            </div>
            {url && (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-break"
                >
                  {url}
                </a>
            )}
          </CardText>
        </div>
      </div>
  );
};

export default ContentInfo;
