import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Spinner,
  Table,
  Row,
  Col,
  Input,
  Pagination as ReactstrapPagination,
  PaginationItem,
  PaginationLink,
  Alert,
  Form
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTheme } from "../theme/theme";
import useContent from "../hooks/useContent";
import { useParams, useNavigate } from "react-router-dom";
import { FaPlus, FaEye, FaTrash, FaCommentDots } from "react-icons/fa";
import "./Investigate.css";
import { DropdownActions } from "../components/dropdownAction/DropdownAction";
import ReactMarkdown from "react-markdown";
import { formatDate } from '../utils/dateUtils';
import SearchBar from "../components/searchbar/SearchBar";
import FilterSortBar from "../components/filterSortBar/FilterSortBar";
import { DeleteModal } from "../components/deleteModal/DeleteModal";

const Investigate = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortKey, setSortKey] = useState("title");
  const [filterKey, setFilterKey] = useState("all");
  const [dateFilterKey, setDateFilterKey] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [multiDeleteModal, setMultiDeleteModal] = useState(false);
  const [singleDeleteModal, setSingleDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { contentId } = useParams();
  const {
    content,
    loading,
    getContent,
    deleteContentItem
  } = useContent(contentId);
  const navigate = useNavigate();

  const toggleMultiDeleteModal = () => setMultiDeleteModal(!multiDeleteModal);
  const toggleSingleDeleteModal = () => setSingleDeleteModal(!singleDeleteModal);

  const filterByDateRange = (items, range) => {
    const now = new Date();
    return items.filter((item) => {
      const dateToCompare = range.includes("updated")
          ? new Date(item.updated)
          : new Date(item.created);
      const diffInDays = (now - dateToCompare) / (1000 * 60 * 60 * 24);
      switch (range) {
        case "created_week":
        case "updated_week":
          return diffInDays <= 7;
        case "created_month":
        case "updated_month":
          return diffInDays <= 30;
        case "created_year":
        case "updated_year":
          return diffInDays <= 365;
        default:
          return true;
      }
    });
  };

  const applyFilterAndSort = useCallback(
      (items) => {
        let result = items;

        if (filterKey !== "all") {
          result = result.filter((item) => item.type === filterKey);
        }

        if (dateFilterKey !== "all") {
          result = filterByDateRange(result, dateFilterKey);
        }

        result.sort((a, b) => {
          if (sortKey === "latest") {
            return new Date(b.updated) - new Date(a.updated);
          } else if (sortKey === "oldest") {
            return new Date(a.updated) - new Date(b.updated);
          } else if (sortKey === "title") {
            return a.title.localeCompare(b.title);
          }
          return 0;
        });

        return result;
      },
      [filterKey, dateFilterKey, sortKey]
  );

  useEffect(() => {
    const filtered = content.filter(
        (item) =>
            (item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.text && item.text.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredData(applyFilterAndSort(filtered));
  }, [content, filterKey, dateFilterKey, sortKey, searchTerm, applyFilterAndSort]);

  const uniqueTypes = [...new Set(content.map((item) => item.type))];

  const handleRowClick = (id) => {
    setExpandedRow((prevExpandedRow) => (prevExpandedRow === id ? null : id));
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(id)
            ? prevSelectedRows.filter((rowId) => rowId !== id)
            : [...prevSelectedRows, id]
    );
  };

  const handleDeleteSelected = () => setMultiDeleteModal(true);

  const handleDeleteConfirmed = async () => {
    try {
      if (selectedRows.length === 0) {
        console.warn("Ingen rader valgt for sletting");
        return;
      }

      console.log("Sletter valgte rader:", selectedRows);
      await Promise.all(selectedRows.map((id) => deleteContentItem(id)));
      await getContent();
      setFilteredData((prevData) => prevData.filter((item) => !selectedRows.includes(item.id)));
      setSelectedRows([]);
      setMultiDeleteModal(false);
    } catch (error) {
      console.error("Feil ved sletting av innhold:", error);
      setErrorMessage(t("delete_content_error"));
    }
  };

  const handleDeleteRow = (id) => {
    setRowToDelete(id);
    setSingleDeleteModal(true);
  };

  const handleDeleteRowConfirmed = async () => {
    try {
      await deleteContentItem(rowToDelete);
      getContent();
      setFilteredData((prevData) => prevData.filter((item) => item.id !== rowToDelete));
      setSingleDeleteModal(false);
      setRowToDelete(null);
    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  const handleCreateThreadFromRow = async (id) => {
    try {
      const contentItem = content.find((item) => item.id === id);
      if (contentItem) {
        navigate("/dialog", { state: { contentIds: [id], contentTitles: [contentItem.title] } });
      }
    } catch (error) {
      console.error("Error creating thread from row:", error);
    }
  };

  const totalPages = Math.ceil(filteredData.length / pageSize);

  const handlePageChange = (page) => setCurrentPage(page);

  const paginatedData = filteredData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
  );

  const handleCreateThread = () => {
    if (selectedRows.length > 0) {
      const initialContentData = {
        contentIds: selectedRows,
        contentTitles: selectedRows.map(
            (id) => content.find((item) => item.id === id).title
        ),
      };
      navigate("/dialog", { state: initialContentData });
    }
  };

  const handleNavigateToContent = (id) => {
    navigate(`/content/${id}`);
  };

  if (loading) {
    return (
        <Container className="text-center mt-5">
          <Spinner color="primary" />
        </Container>
    );
  }

  return (
      <Container
          fluid
          className="investigate min-vh-100 p-5 container-xl"
          style={{ backgroundColor: theme === "light" ? "#ffffff" : "#262626" }}
      >
        <Row className="pb-3 pt-3 position-sticky fixed-top d-flex justify-content-center mt-3 mb-5">
          <Col md="6">
            <SearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                placeholder={t("investigate_placeholder")}
            />
          </Col>
        </Row>

        {errorMessage && (
            <Alert color="danger" toggle={() => setErrorMessage(null)}>
              {errorMessage}
            </Alert>
        )}

        <Row className="justify-content-between align-items-center my-3">
          <Col className="d-flex flex-grow-1 justify-content-start align-items-center">
          <span className="selected-counter">
            {t("selected_items", { count: `${selectedRows.length}/${filteredData.length}` })}
          </span>
            <DropdownActions
                showCopyButton={false}
                toggleDeleteModal={handleDeleteSelected}
                primaryActionIcon={FaPlus}
                primaryActionLabel={t("create_thread")}
                handlePrimaryAction={handleCreateThread}
                disabled={selectedRows.length === 0}
            />
          </Col>
          <Col className="d-flex flex-grow-1 justify-content-end align-items-center">
            <Form inline>
              <FilterSortBar
                  filterKey={filterKey}
                  setFilterKey={setFilterKey}
                  sortKey={sortKey}
                  setSortKey={setSortKey}
                  dateFilterKey={dateFilterKey}
                  setDateFilterKey={setDateFilterKey}
                  uniqueTypes={uniqueTypes}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
              />
            </Form>
          </Col>
        </Row>

        <Table striped bordered responsive hover>
          <thead>
          <tr>
            <th>
              <Input
                  type="checkbox"
                  onChange={(e) =>
                      setSelectedRows(
                          e.target.checked ? paginatedData.map((item) => item.id) : []
                      )
                  }
                  checked={
                      paginatedData.length > 0 &&
                      selectedRows.length === paginatedData.length
                  }
              />
            </th>
            <th>{t("title")}</th>
            <th>{t("description")}</th>
            <th>{t("type")}</th>
            <th>{t("created_date")}</th>
            <th>{t("updated_date")}</th>
            <th>{t("actions")}</th>
          </tr>
          </thead>
          <tbody className="content-table-list">
          {paginatedData.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center text-primary">
                  {t("no_results_found")}
                </td>
              </tr>
          ) : (
              paginatedData.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr
                        style={{
                          cursor: "pointer",
                          backgroundColor: selectedRows.includes(item.id) ? "#f0f8ff" : "",
                        }}
                        onClick={() => handleRowClick(item.id)}
                    >
                      <td>
                        <Input
                            type="checkbox"
                            checked={selectedRows.includes(item.id)}
                            onChange={() => handleRowSelect(item.id)}
                            onClick={(e) => e.stopPropagation()}
                        />
                      </td>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      <td>{item.type}</td>
                      <td>{formatDate(item.created)}</td>
                      <td>{formatDate(item.updated)}</td>
                      <td>
                        <button
                            className="view-icon-button"
                            onClick={() => handleNavigateToContent(item.id)}
                            title={t("view_content")}
                        >
                          <FaEye />
                        </button>
                        <button
                            className="view-icon-button"
                            onClick={() => handleDeleteRow(item.id)}
                            title={t("delete_content")}
                            style={{ marginLeft: '10px', backgroundColor: '#dc3545' }}
                        >
                          <FaTrash />
                        </button>
                        <button
                            className="view-icon-button"
                            onClick={() => handleCreateThreadFromRow(item.id)}
                            title={t("create_thread")}
                            style={{ marginLeft: '10px', backgroundColor: '#28a745' }}
                        >
                          <FaCommentDots />
                        </button>
                      </td>
                    </tr>
                    {expandedRow === item.id && (
                        <tr className="expanded-row">
                          <td colSpan="7" className="expanded-content">
                            <div>
                              <ReactMarkdown>{item.text || "Ingen ytterligere informasjon tilgjengelig."}</ReactMarkdown>
                            </div>
                          </td>
                        </tr>
                    )}
                  </React.Fragment>
              ))
          )}
          </tbody>
        </Table>

        <ReactstrapPagination aria-label="Page navigation">
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink first onClick={() => handlePageChange(1)} />
          </PaginationItem>
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
          </PaginationItem>
          {[...Array(totalPages)].map((_, index) => (
              <PaginationItem active={index + 1 === currentPage} key={index}>
                <PaginationLink onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
          </PaginationItem>
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink last onClick={() => handlePageChange(totalPages)} />
          </PaginationItem>
        </ReactstrapPagination>

        {/* Multi Delete Modal */}
        <DeleteModal
            isOpen={multiDeleteModal}
            toggle={toggleMultiDeleteModal}
            handleDelete={handleDeleteConfirmed}
            className="modal-center"
        />

        {/* Single Delete Modal */}
        <DeleteModal
            isOpen={singleDeleteModal}
            toggle={toggleSingleDeleteModal}
            handleDelete={handleDeleteRowConfirmed}
            className="modal-center"
        />
      </Container>
  );
};

export default Investigate;
