// src/components/Loader.js
import React from 'react';
import './Loader.css'; // For å legge til litt CSS-styling

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="spinner"></div>
            <p>Loading, please wait...</p>
        </div>
    );
};

export default Loader;