import React from "react";
import { ListGroupItem } from "reactstrap";
import "./ContentListItem.css";
import { useTheme } from "../../theme/theme";
import { formatDate } from "../../utils/dateUtils";

const ContentListItem = ({ content, onSelectContent, isSelected }) => {
    const { theme } = useTheme();

    return (
        <ListGroupItem
            onClick={() => onSelectContent(content)}
            style={{
                backgroundColor:
                    theme === "light"
                        ? "#ffffff"
                        : "var(--background-color-darker)",
                color: theme === "light" ? "#000000" : "#ffffff",
            }}
            className={`d-flex justify-content-between align-items-center content-list-item ${
                isSelected ? "selected" : ""
            }`}
        >
            <div className="d-flex justify-content-between align-items-center w-100">
                <div className="content-details">
                    <h5 className="mb-2">{content.title}</h5>
                    <p className="mb-2">{content.description}</p>
                    <p className="text-end">{formatDate(content.updated)}</p>
                </div>
            </div>
        </ListGroupItem>
    );
};

export default ContentListItem;
