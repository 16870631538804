import React, { useEffect, useState } from "react";
import { fetchModels } from "../../api/mockApi";
import ModelListItem from "./ModelListItem";
import { ListGroup, Spinner } from "reactstrap";
import "./ModelList.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/theme";

const ModelList = () => {
  const [models, setModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModels, setShowModels] = useState(false);
  const { t } = useTranslation();
  const { theme } = useTheme();

  const toggleModels = () => setShowModels(!showModels);

  useEffect(() => {
    const getModels = async () => {
      try {
        const data = await fetchModels();
        setModels(data);
      } catch (error) {
        console.error("Failed to fetch models:", error);
      } finally {
        setLoading(false);
      }
    };

    getModels();
  }, []);

  const handleToggle = (modelId) => {
    setSelectedModels((prevSelectedModels) =>
      prevSelectedModels.includes(modelId)
        ? prevSelectedModels.filter((id) => id !== modelId)
        : [...prevSelectedModels, modelId]
    );
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div className="text-start">
      <div
        className="btn btn-outline-primary mb-4 mt-4"
        onClick={toggleModels}
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--background-color)"
              : "var(--background-color-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
          borderColor:
            theme === "light" ? "var(--primary-light)" : "var(--primary-dark)",
        }}
      >
        {showModels ? (
          <>
            {t("hide_models")} <FaChevronUp />
          </>
        ) : (
          <>
            {t("show_models")} <FaChevronDown />
          </>
        )}
      </div>

      {showModels && (
        <ListGroup
          className="model-list w-100"
          style={{
            backgroundColor:
              theme === "light"
                ? "var(--background-color)"
                : "var(--background-color-dark)",
            color: theme === "light" ? "#000000" : "var(--primary-dark)",
            borderColor:
              theme === "light"
                ? "var(--primary-light)"
                : "var(--primary-dark)",
          }}
        >
          {models.map((model) => (
            <ModelListItem
              key={model.id}
              model={model}
              onToggle={handleToggle}
              isSelected={selectedModels.includes(model.id)}
            />
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default ModelList;
